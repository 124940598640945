// formsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const formsSlice = createSlice({
    name: 'forms',
    initialState: {
        forms: [],
        loading: false,
        error: null,
        current:null,
        deletedForm:null
    },
    reducers: {
        updateForms: (state, action) => {
            state.forms = action.payload;
        },
        pushANewForm: (state, action) => {
            state.forms = [action.payload, ...state.forms];
        },
        setCurrentForm: (state,action) => {
            state.current = action.payload
        },
        setDeletedForm: (state,action) => {
            state.deletedForm = action.payload
        }
    }
});

export const { updateForms, pushANewForm, setCurrentForm, setDeletedForm } = formsSlice.actions;
export default formsSlice.reducer;
